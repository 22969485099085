import styled, { css } from 'styled-components';

import { devices } from '../../styles';
import PurchaseModal from './PurchaseModal';
import GalleryModal from './GalleryModal';

const Image = styled.div`
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '864px')};
  min-width: 444px;
  flex: 45%;
  margin-bottom: 48px;
  cursor: pointer;

  @media ${devices.mobile} {
    min-width: 100%;
  }
`;

const Description = styled.div`
  font-size: 16px;
  width: 416px;
`;

const Price = styled.span`
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 24px;
  display: block;
  font-weight: 600;

  ${({ isSold }) =>
    isSold &&
    css`
      color: ${({ theme }) => theme.text.sold};
      font-style: italic;
    `};
`;

const Gallery = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const CarPage = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 48px;

  > ${Image} {
    flex: 1;
    margin-right: 48px;
  }
`;

CarPage.Image = Image;
CarPage.Description = Description;
CarPage.Price = Price;
CarPage.Gallery = Gallery;
CarPage.Modal = PurchaseModal;
CarPage.GalleryModal = GalleryModal;

export default CarPage;
