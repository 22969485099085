import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import numeral from 'numeral';
import ClickOutside from 'react-click-outside';
import Helmet from 'react-helmet';
import querystring from 'querystring';

import ContentTitle from '../components/ContentTitle';
import Layout from '../components/layout';
import Image from '../components/image';
import CarPage from '../components/CarPage';
import Form from '../components/Form';
import Icon from '../components/Icon';

const formTarget =
  '/.netlify/functions/mail?to=info@limerencemotors.com&title=Car Contact Request';

class CarPageTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      isGalleryOpen: false,
      galleryIndex: 0,
      formData: {
        name: '',
        email: '',
        phone: '',
        message: '',
      },
      isSending: false,
    };
  }

  toggleModal = () => this.setState({ isModalOpen: !this.state.isModalOpen });

  toggleGallery = galleryIndex =>
    this.setState({ isGalleryOpen: !this.state.isGalleryOpen, galleryIndex });

  setGalleryIndex = galleryIndex => this.setState({ galleryIndex });

  handleInput = e => {
    const { formData } = this.state;

    formData[e.target.name] = e.target.value;

    return this.setState({ formData });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { formData } = this.state;
    this.setState({ isSending: true });

    const car = get(this, 'props.data.contentfulCar');
    formData.car = car.name;

    const body = querystring.stringify(formData);

    fetch(formTarget, {
      method: 'POST',
      body,
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Failed to send form.');
        }
        return response.json();
      })
      .then(json => {
        if (json.status !== 200) {
          throw new Error('Failed to send form.');
        }

        return this.setState({
          formSuccess: true,
          formError: null,
          isSending: false,
        });
      })
      .catch(err =>
        this.setState({ formError: err.message, isSending: false })
      );
  };

  render() {
    const car = get(this, 'props.data.contentfulCar');
    const { galleryIndex, isGalleryOpen } = this.state;

    const sliderSettings = {
      dots: true,
      arrows: true,
    };

    const slicedImages = car.images.slice(1);

    const isLeftButtonActive = galleryIndex !== 0;
    const isRightButtonActive = galleryIndex !== car.images.length - 1;

    const { formError, formSuccess, isSending } = this.state;

    return (
      <Layout>
        <Helmet meta={[{ name: 'description', content: car.shortDescription }]}>
          <title>{car.name}</title>
        </Helmet>
        <ContentTitle>{car.name}</ContentTitle>
        <CarPage>
          <CarPage.Image onClick={() => this.toggleGallery(0)}>
            <Image sizes={car.images[0].sizes} />
          </CarPage.Image>
          <CarPage.Description>
            <div
              dangerouslySetInnerHTML={{
                __html: car.description.childMarkdownRemark.html,
              }}
            />
            <CarPage.Price isSold={car.sold}>
              {car.sold && 'Sold'}
              {!car.sold && numeral(car.cost).format('$0,0')}
            </CarPage.Price>
            {!car.sold && (
              <Form.Button onClick={() => this.toggleModal()}>
                <Icon name="car" /> Get This Vehicle
              </Form.Button>
            )}
          </CarPage.Description>
        </CarPage>
        <CarPage.Gallery>
          {slicedImages &&
            slicedImages.map((image, index) => (
              <CarPage.Image
                key={image.id}
                maxWidth="48%"
                onClick={() => this.toggleGallery(index + 1)}
              >
                <Image sizes={image.sizes} />
              </CarPage.Image>
            ))}
        </CarPage.Gallery>
        {/* Popup image modal */}
        {isGalleryOpen && (
          <CarPage.GalleryModal>
            <CarPage.GalleryModal.CloseButton
              onClick={() => this.toggleGallery(0)}
            >
              <Icon name="times" />
            </CarPage.GalleryModal.CloseButton>
            <CarPage.GalleryModal.Icon
              isActive={isLeftButtonActive}
              onClick={() =>
                isLeftButtonActive
                  ? this.setGalleryIndex(galleryIndex - 1)
                  : null
              }
            >
              <Icon name="angle-left" />
            </CarPage.GalleryModal.Icon>
            <CarPage.GalleryModal.Image>
              <Image sizes={car.images[galleryIndex].sizes} />
            </CarPage.GalleryModal.Image>
            <CarPage.GalleryModal.FullSizeLink>
              <a
                href={car.images[galleryIndex].sizes.src}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="search-plus" /> Open this image full size
              </a>
            </CarPage.GalleryModal.FullSizeLink>
            <CarPage.GalleryModal.Icon
              isActive={isRightButtonActive}
              onClick={() =>
                isRightButtonActive
                  ? this.setGalleryIndex(galleryIndex + 1)
                  : null
              }
              position="right"
            >
              <Icon name="angle-right" />
            </CarPage.GalleryModal.Icon>
          </CarPage.GalleryModal>
        )}
        <ClickOutside
          onClickOutside={() =>
            this.state.isModalOpen ? this.toggleModal() : null
          }
        >
          <CarPage.Modal isModalOpen={this.state.isModalOpen}>
            <CarPage.Modal.CloseButton onClick={() => this.toggleModal()}>
              <Icon name="times" />
            </CarPage.Modal.CloseButton>
            <CarPage.Modal.Title>
              {formSuccess ? 'Message sent!' : 'Are you interested?'}
            </CarPage.Modal.Title>
            {formSuccess ? (
              <CarPage.Modal.Description>
                Thanks for reaching out! We will get in contact with you soon!
              </CarPage.Modal.Description>
            ) : (
              <>
                <CarPage.Modal.Description>
                  Talk to us about the <strong>{car.name}</strong> by filling
                  out the form below. We will get back to you as soon as we can!
                </CarPage.Modal.Description>
                {formError && <div>Error: {formError}</div>}
                <form onSubmit={this.handleSubmit}>
                  <Form.Label htmlFor="name">Name</Form.Label>
                  <Form.Input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Your Name"
                    required
                    onChange={this.handleInput}
                    value={this.state.formData.name}
                  />
                  <Form.Label htmlFor="email">Email Address</Form.Label>
                  <Form.Input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="email@address.com"
                    required
                    onChange={this.handleInput}
                    value={this.state.formData.email}
                  />
                  <Form.Label htmlFor="phone">Phone Number</Form.Label>
                  <Form.Input
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="012-345-6789"
                    required
                    onChange={this.handleInput}
                    value={this.state.formData.phone}
                  />
                  <Form.Label htmlFor="message">Message</Form.Label>
                  <Form.TextArea
                    id="message"
                    name="message"
                    placeholder="Type Here"
                    required
                    style={{ height: '125px', minHeight: '125px' }}
                    onChange={this.handleInput}
                    value={this.state.formData.message}
                  />
                  <Form.Button
                    type="submit"
                    style={{ float: 'right' }}
                    disabled={isSending}
                  >
                    <Icon name="envelope" /> {isSending ? 'Sending...' : 'Send'}
                  </Form.Button>
                </form>
              </>
            )}
          </CarPage.Modal>
        </ClickOutside>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query CarBySlug($slug: String!) {
    contentfulCar(slug: { eq: $slug }) {
      name
      slug
      shortDescription
      description {
        childMarkdownRemark {
          html
        }
      }
      sold
      cost
      images {
        id
        sizes(maxWidth: 1400, resizingBehavior: SCALE) {
          ...GatsbyContentfulSizes_withWebp
        }
      }
    }
  }
`;

export default CarPageTemplate;
