import styled, { css } from 'styled-components';

import { devices } from '../../styles';

const Image = styled.div`
  margin: 0 auto;
  width: 80%;
`;

const CloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  outline: 0 none;
  top: 25px;
  right: 25px;
  width: 30px;
  height: 30px;
  color: ${({ theme }) => theme.text.standard};
  border: 0;
  background-color: transparent;
  font-size: 30px;
  z-index: 20001;
`;

const Icon = styled.button`
  cursor: pointer;
  position: absolute;
  font-size: 30px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 50px;
  height: 50px;
  border: 0;
  padding: 0;
  display: block;
  outline: 0 none;
  background-color: transparent;
  color: ${({ theme }) => theme.text.standard};

  ${({ position }) =>
    position === 'right'
      ? css`
          right: 3%;

          @media ${devices.mobile} {
            right: 0;
          }
        `
      : css`
          left: 3%;

          @media ${devices.mobile} {
            left: 0;
          }
        `};

  ${({ isActive }) =>
    !isActive &&
    css`
      color: rgba(0, 0, 0, 0.4);
    `};
`;

const FullSizeLink = styled.div`
  margin: 0 auto;
  display: none;
  position: absolute;
  font-size: 16px;
  text-align: center;
  bottom: 25%;
  width: 100%;

  > a {
    color: ${({ theme }) => theme.text.link};
  }

  @media ${devices.mobile} {
    display: block;
  }
`;

const GalleryModal = styled.div`
  position: fixed;
  background-color: rgba(255, 255, 255, 0.9);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20000;
  display: flex;
  align-items: center;
`;

GalleryModal.Image = Image;
GalleryModal.CloseButton = CloseButton;
GalleryModal.Icon = Icon;
GalleryModal.FullSizeLink = FullSizeLink;

export default GalleryModal;
